import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import About from '../components/About';
import Welcome from '../components/Welcome';
import NavigationBar from '../components/NavigationBar';
import Footer from '../components/Footer';
import ProjectsGrid from './ProjectsGrid';


export default class Dashboard extends React.Component {

  constructor(props){
    super(props);
  
    this.state = {

      viewMode: 0,
      middleText: [
        {headline:'WELCOME',text:<p className='WelcomeText'>to <b>rcoledev.com</b>, the portfolio site for Randy Cole.<br/> Thanks for visiting.</p>},
        {headline:'ABOUT',text:'Interests and Skills'},
        {headline:'PROJECTS',text:'These are projects I worked on as a solo developer or as a part of a team'},
        {headline:'CONTACT',text:`Please feel free to contact me using the links below.
        I look forward to chatting with you.`}
      ]
 
    }

    this.selectWelcome = this.selectWelcome.bind(this);
    this.selectAbout = this.selectAbout.bind(this);
    this.selectProjects = this.selectProjects.bind(this);
    this.selectContact = this.selectContact.bind(this);


  }
 
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  selectWelcome(){

    window.scrollTo(0, 0);
 
    this.setState({
      viewMode: 0
    })
  
  }
    
  selectAbout(){

    window.scrollTo(0, 0);

    this.setState({
      viewMode: 1
    })
 
  }

  selectProjects(){

    window.scrollTo(0, 0);

    this.setState({
      viewMode: 2
    })
 
  }

  selectContact(){

    window.scrollTo(0, 0);

    this.setState({
      viewMode: 3
    })
 
  }

  render() {
 
    return(
      <div className='Dashboard' >
        {this.state.viewMode === 1 &&
          <ReactCSSTransitionGroup
            transitionName='AppearTransition'
            transitionAppear={true}
            transitionAppearTimeout={1000}
            transitionEnter={false}
            transitionLeave={false}
          >
            <About/>
          </ReactCSSTransitionGroup>
        }
        {this.state.viewMode === 2 &&
          <ReactCSSTransitionGroup
            transitionName='AppearTransition'
            transitionAppear={true}
            transitionAppearTimeout={1000}
            transitionEnter={false}
            transitionLeave={false}
          >
            <ProjectsGrid/>
          </ReactCSSTransitionGroup>
          }
        {(this.state.viewMode === 0 || this.state.viewMode === 3)
          &&
          <ReactCSSTransitionGroup
            transitionName='AppearTransition'
            transitionAppear={true}
            transitionAppearTimeout={1000}
            transitionEnter={false}
            transitionLeave={false}
          >
            <Welcome/>
          </ReactCSSTransitionGroup>
          }
        <NavigationBar 
           selectWelcome={this.selectWelcome}
           selectAbout={this.selectAbout}
           selectProjects={this.selectProjects}
           selectContact={this.selectContact}
           viewMode={this.state.viewMode}
           middleText={this.state.middleText}
        />
        <Footer/>
      </div>
    );

  }
 
}