import React from 'react';
import profilePic from '../resources/images/Profile3.jpg';
import spaceInvaders from '../resources/images/SpiritedFearfulBactrian-size_restricted.gif';
// import pizzaFractal from '../resources/images/pizza.webp';  
import Cartoon from '../resources/images/cartoon_01.jpg'; 
import Chair from '../resources/images/Chair_Character_02.jpg';
import Backpack from '../resources/images/Backpack_Character.jpg';
import Diversity from '../resources/images/Diversity.jpg'; 
import Trucks from '../resources/images/Trucks.jpg';

//Fortran, it's not dead, it's just resting..
// --http://wiki.c2.com/?DeadLanguageFortran
 
 
export default function About(props) {

  window.scrollTo(0,0);
 
  const skills = [

    {languages:['Swift',
                'JavaScript',
                'Objective C',
                'Visual Basic',
                ]
              },
    {frontEnd:['SwiftUI',
               'SpriteKit',
               'SceneKit',
               'React',
               'Vue']
              },
    {backEnd:['FireBase',
              'NodeJS',
              'Express',
              'MongoDB',
              'Cloudinary']
              },
    {devTools:['GitHub',
               'Heroku',
               'TravisCI',
               'Netlifly']
                }          
    ]
    
  const skillsListLanguages = skills[0].languages.map((item,index)=>{

    return(
      <li className='SkillListItem' key={index}>{item}</li>
    )
     
  });

  const skillsListFrontEnd = skills[1].frontEnd.map((item,index)=>{

    return(
      <li className='SkillListItem' key={index}>{item}</li>
    )
     
  });

  const skillsListBackEnd = skills[2].backEnd.map((item,index)=>{

    return(
      <li className='SkillListItem' key={index}>{item}</li>
    )
     
  });

  const skillsListDevTools = skills[3].devTools.map((item,index)=>{

    return(
      <li className='SkillListItem' key={index}>{item}</li>
    )
     
  });
 
 
  return(
 
    <div  className='AboutContainer'>
       
      <img  src={profilePic} height={'250'} className= 'AboutImages' alt={'Randy'}/>
      <p className='AboutText'>
        My name is <b>Randy Cole</b>, I am a developer
        with a wide array of skills, interests and experience.
        Along with being a developer I also work as a
        <a className='NavBarText' href='https://www.rcoledesign.com'> graphic designer and illustrator</a> for both print and digital media.
        In my free time I enjoy my family, building things, drawing, animating stuff, hiking, movies, tv shows, robots,
        video games, dog walking and making pizza.
      </p>  
          <ul className='IllustrationList'>
            <li><p className='AboutText'>Some of My Design / Illustration work</p></li>
            <li><img src={Chair} alt='Chair' width={'200px'}/></li>
            <li><img src={Cartoon} alt='Cartoon' width={'200px'}/></li>
            <li><img src={Trucks} alt='Trucks' width={'200px'}/></li>
            <li><img src={Diversity} alt='Diversity' width={'200px'}/></li>
            <li><a href='https://www.rcoledesign.com' className='NavBarText' style={{color:'orange', textdecoration: 'none'}}><strong><p className='AboutText' style={{color:'orange', textDecoration: 'none'}}>See More</p></strong></a></li>
          </ul>
      <p className='AboutText'>
        For me, being a developer has opened the door for the construction of projects that are a fusion of many of
        my creative skills and interests. My goals with development have focused on building things that are fun for
        people to interact with, and which provide entertainment and or information through a well designed, intuitive and functionally elegant
        experience.
      </p>
      <p className='AboutText'>
        My many years of building iOS apps has kept me interested in pursuing knowledge from all kinds of
        development environments. This pursuit has led me to Swift, Javascript, React, Node, Vue, Visual Basic and  
        many more.
        <br/><br/> 
        As always, my desire is to continue learning while being a vital part of a team building
        something awesome.
      </p> 
      <br/>
      <span className='AboutSkillsTitle'><b>My Skills</b> and technology include:</span>
       
      <div className='SkillListContainer'>
      <ul className='SkillList'>Languages {skillsListLanguages}</ul>
      <ul className='SkillList'>Front End {skillsListFrontEnd}</ul>
      <ul className='SkillList'>Back End {skillsListBackEnd}</ul>
      <ul className='SkillList'>Dev Tools {skillsListDevTools}</ul>
      </div>
    </div>

  )

}

//Drawing, Art, computers & coding,
//animation, video games, robots, making movies and sculpture have all been a focused interest of mine.