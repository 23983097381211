import React, { Component } from 'react';

import './App.css';

import Dashboard from './components/Dashboard';

//App-logo spins stuff...
class App extends Component {
  render() {
    return (
      <div className='App'>
        <Dashboard />
      </div>
    );
  }
}

export default App;
