import React, { Component } from 'react';
import docIcon from '../resources/images/docIcon.png';
import Pdf from '../resources/pdf/iOS_Developer_Randy_Cole_resume.pdf';

export default class Resume extends Component {

  render() {

    return (
      <React.Fragment>
        <a className='Links'
           href = {Pdf}
           target='_blank'
           rel='noopener noreferrer'>
           <img src={docIcon} width={'30'} alt='linkedIn'/> resume</a>
      </React.Fragment>
    );
  }
}
 
 