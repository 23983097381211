import React from 'react';
import git from '../resources/images/git.png';
import project_1 from '../resources/images/GoodGlueWebsite.png';
import project_2 from '../resources/images/postypost.png';
import project_3 from '../resources/images/tic_tac_chat.png';
// import project_3 from '../resources/images/goodcall.png';
import project_4 from '../resources/images/italian-5000.png';
import project_5 from '../resources/images/goodglue.png';
import project_0 from '../resources/images/FoodScrapTruck.png';

import { isMobile } from 'react-device-detect';


export default class ProjectsGrid extends React.Component{

  constructor(props) {
    super(props);

    this.state = {
       
        showDetails: null,
        projectsData: [ 
          {
            title: 'Food Scrap Recycling Truck',
            tagline: 'Educational App for Kids!',
            tech: `Swift | SpriteKit | Xcode`,
            description: `Designed and developed an educational kid's app for a compostable bag
            manufacturer to explain the concept of food composting to children in a fun way.
            Custom SpriteKit UI. Touch interaction with programmatic animations. Video playback. Persisted User settings`,
            image: project_0,
            linkText: 'Available in the Apple app store',
            link: null,
            git: null  
          },
        {
          title: 'Good Glue Website',
          tagline: 'Catalog & Shopping site for Good Glue',
          tech: `JavaScript | React`,
          description: `A mobile first website with searchable and filtered results`,
          image: project_1,
          linkText: 'See the deployed version',
          link: 'https://www.goodglue.com/',
          git: null 
        },
        {
          title: 'Good Glue Apps',
          tagline: 'Fun Vehicle Apps for Kids!',
          tech: `Swift | Objective C | OpenGL | Xcode`,
          description: `Designed and developed a series of over 40 apps for kids. Touch interaction with programmatic
          animations.`,
          image: project_5,
          linkText: 'Available in the Apple app store',
          link: null,
          git: null  
        },
        {
          title: 'Posty Post',
          tagline: 'Arrange your notes',
          tech: `JavaScript | React | Redux`,
          description: `A fun web app with simple arrangeable notes.
          Mobile version notes compact and expand`,
          image: project_2,
          linkText: 'See the deployed version',
          link: 'https://postypost.herokuapp.com/',
          git:'https://github.com/R-Cole/post-it-thing'  
        },
        // {
        //   title: 'TIC-TAC-CHAT ',
        //   tagline: 'Chat and Play at the same time',
        //   tech: 'JavaScript | React Native | FireBase',
        //   description: `React Native app test that allows multiple users to log in anonymously and play a
        //   group game of five way tic-tac-toe while text chatting in real time. The text and game data
        //   are persisted using FireBase DB.`,
        //   image: project_3,
        //   linkText: 'See this app in a simulator',
        //   link: 'https://appetize.io/embed/8bnmakzrptf1hv9dq7v7bnteem?autoplay=false&debug=true&device=iphone6s&deviceColor=black&embed=true&orientation=portrait&screenOnly=false&xDocMsg=true&xdocMsg=true&params=%7B%22EXKernelLaunchUrlDefaultsKey%22:%22exp:%2F%2Fexpo.io%2F@git%2Fgithub.com%2FR-Cole%2FTic-Tac-Chat--React-Native%2B5khyozYs6%22,%22EXKernelDisableNuxDefaultsKey%22:true%7D&scale=75&osVersion=11.4',
        //   git:'https://github.com/R-Cole/Tic-Tac-Chat--React-Native' 
        // },
        // {
        //   title: 'ITALIAN 5000!',
        //   tech: 'JavaScript | React | Redux | MongoDB | Node/Express',
        //   tagline: 'Learn Italian the smart way!',
        //   description: `A simple language learning app utilizing
        //   a spaced repitition algorithm. The algorithm presents the words you get
        //   wrong more often and the words you get right less often over time. Users create
        //   an account and log in to continue testing progress. User data is persisted in a
        //   Mongo DB.`,
        //   image: project_4,
        //   linkText: 'See the deployed version',
        //   link: 'https://fathomless-savannah-97450.herokuapp.com/',
        //   git:'https://github.com/R-Cole/Italian-5000' 
        // },
        
        // {
        //   title: 'Quick News!',
        //   tagline: 'News blurbs',
        //   tech: `JavaScript | React Native`,
        //   description: `A mobile news app that gathers news current stories via
        //   the free News API. Once a user reads a story it is shown muted.`,
        //   image: project_1,
        //   linkText: 'See this app in a simulator',
        //   link: 'http://appstore.com/goodglue',
        //   git:'https://github.com/thinkful-ei26/randyc-gc-client' 
        // },
        
        
        
      ]
 
    }
  

  }

  onMouseOver(index){

    this.setState({

      showDetails: index

    })

      
  }

  onMouseExit(){

    this.setState({

      showDetails: null

    })


  }

  render(){

    let gridContent = null;

    //Desktop
    if(!isMobile){

      gridContent = this.state.projectsData.map((item,index) => {

      return (
                 
                <li key={this.state.projectsData[index].title}>
                  {(this.state.showDetails === index)
                    ? <div className='gridItemContainer_expanded'>
                        
                        <div className='gridDetailsContainer'>
                          {(this.state.projectsData[index].title)
                          ?<div>
                          <p className='gridText_Title'>{this.state.projectsData[index].title}</p> 
                          <p className='gridText_TagLine'>{this.state.projectsData[index].tagline}</p> 
                          <p onMouseLeave={()=>{this.onMouseExit()}} className='gridText'>{this.state.projectsData[index].description}</p>
                           
                          <p className='gridTechText'>Built with: <br/>{this.state.projectsData[index].tech}</p>
                           
                          <a className='Links' href={this.state.projectsData[index].link} target='_blank' rel='noopener noreferrer'>
                            <p className='gridProjectLinks'>{this.state.projectsData[index].linkText}</p> 
                          </a>
                          </div>
                          : null
                          }
                          {(this.state.projectsData[index].git)
                            ?  <a href={this.state.projectsData[index].git} target='_blank' rel='noopener noreferrer'>
                                <img className='gridProjectGitImage' src={git} alt={'link ot github'} ></img>
                              </a>
                            : null
                          }
                        </div>

                        <div className='gridBlackSolid'></div>

                        <img className='gridImage' src ={this.state.projectsData[index].image} alt={this.state.projectsData[index].title}/>

                      </div>
                    : <div className='gridItemContainer'>
                        <div className='gridDetailsContainer'>
                          <p className='gridText_Title'>{this.state.projectsData[index].title}</p> 
                          <p className='gridText_TagLine'>{this.state.projectsData[index].tagline}</p> 
                        </div>
                        <img onMouseEnter={()=>{this.onMouseOver(index)}}
                             className='gridImage'
                             src ={this.state.projectsData[index].image}
                             alt={this.state.projectsData[index].title}
                        />
                        
                      </div>
                  }
                </li>
                  
      )
 
      });
    
    }
   
    //Mobile 
    if(isMobile){

      gridContent = this.state.projectsData.map((item,index) => {

      return (
                 
                <li key={this.state.projectsData[index].title} 
                    // className='Z_List_Item' 
                    
                    >
                  
                  {(this.state.showDetails === index)
                    ? <div className='gridItemContainer_expanded'>
                        
                        <div className='gridDetailsContainer'>
                          <p className='gridText_Title'>{this.state.projectsData[index].title}</p> 
                          <p className='gridText_TagLine'>{this.state.projectsData[index].tagline}</p> 
                          <p onTouchStart={()=>{this.onMouseExit()}} className='gridText'>{this.state.projectsData[index].description}</p>
                           
                          <p className='gridTechText'>Built with: <br/>{this.state.projectsData[index].tech}</p>
                           
                          <a className='Links' href={this.state.projectsData[index].link} target='_blank' rel='noopener noreferrer'>
                            <p className='gridProjectLinks'>{this.state.projectsData[index].linkText}</p> 
                          </a>
                          {(this.state.projectsData[index].git)
                            ?  <a href={this.state.projectsData[index].git} target='_blank' rel='noopener noreferrer'>
                                <img className='gridProjectGitImage' src={git} alt={'link ot github'} ></img>
                              </a>
                            : null
                          }
                        </div>

                        <div className='gridBlackSolid'></div> 
                        <img className='gridImage' src ={this.state.projectsData[index].image} alt={this.state.projectsData[index].title}/>
                      </div>
                    : <div className='gridItemContainer'>
                        <div className='gridDetailsContainer'>
                          <p className='gridText_Title'>{this.state.projectsData[index].title}</p> 
                          <p className='gridText_TagLine'>{this.state.projectsData[index].tagline}</p> 
                        </div>
                        <img onTouchStart={()=>{this.onMouseOver(index)}}  className='gridImage' src ={this.state.projectsData[index].image} alt={this.state.projectsData[index].title}/>
                      </div>
                  }
                </li>
                  
      )
 
      });
    
    }
 

    return(
      
      <div className='ProjectPageContainer'> 
        <ul className='mainGrid_Container'>
          {gridContent}
        </ul>
      </div>
    
    
    );

  };
 
};