import React from 'react';
import git from '../resources/images/git.png';
import linkedIn from '../resources/images/linkedIn.png';
import Resume from '../components/Resume';


export default function Footer(props) {
 
  return(
      <React.Fragment>
       <div className='FooterContainer'>
        <ul className='FooterContacts'>
        <li className='FooterListItem'> 
        email: <a className='Links' href='mailto:randy@goodglue.com'>randy@goodglue.com</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
        </li>
        <li className='FooterListItem'>
        <a className='Links'
                     href='https://github.com/R-Cole'
                     target='_blank'
                     rel='noopener noreferrer'> 
        <img src={git} width={'40'} alt='git'/> 
        </a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
        </li>
        <li className='FooterListItem'>
        <a className='Links'
                     href='https://www.linkedin.com/in/randy-cole'
                     target='_blank'
                     rel='noopener noreferrer'>
         <img src={linkedIn} width={'30'} alt='linkedIn'/> 
         </a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
        </li>
        <li className='FooterListItem'>
          <Resume/>
        </li>
        </ul> 
        </div>
        <div className='FooterContainer'>
        <br/>
        <br/>
        Copyright <b>Randy Cole</b> 2019
      </div> 
      </React.Fragment>
    
  )

}