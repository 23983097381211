import React from 'react';
 
import rcoledev_green from '../resources/images/rcoledev_01_green.png';
// import PageIntro from '../components/PageIntro';

export default function NavigationBar(props) {
 
    //links will drive page changes

    return(
      <div className = 'NavBarContainer'>
        <img src={rcoledev_green} className='RColeDevImage' alt='rcoledev logo' onClick={()=>props.selectWelcome()}/>
        <ul className='NavBarText_container'>
          <li className='NavBarText' onClick={()=>props.selectAbout()}>ABOUT</li>
          <li className='NavBarText' onClick={()=>props.selectProjects()}>PROJECTS</li>
          <li><a style ={{textdecoration: 'none'}} href='https://www.rcoledesign.com' className='NavBarText'>DESIGN</a></li>
          <li className='NavBarText' onClick={()=>props.selectContact()}>CONTACT</li>
        </ul>
        
      </div>
    );
 
}