import React from 'react';
import Painting from '../resources/images/painting.jpg';

export default function Welcome(props) {

return(

  <div className='WelcomeContainer'>
    <img src={Painting} width={'100%'} alt='A painting'/>
  </div>
)


}